import React, { useState, useEffect } from "react";
import { Avatar, Dropdown, Layout, Menu } from "antd";
import { LogoutOutlined, ShopOutlined } from "@ant-design/icons";
import { logOut } from "utils/tools";
import const_config from "utils/const_config";
import logo from "assets/images/Logo_CXM.svg";
import { Enum } from "utils/enums";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import VIcon from "components/VIcon";

const { Header } = Layout;

const { countryCode } = Enum;

type baseNameType =
  | "/fr"
  | "/jp"
  | "/it"
  | "/au"
  | "/nz"
  | "/nl"
  | "/gb"
  | "/be"
  | "/de"
  | "/ch"
  | "/at"
  | "/es"
  | "/us"
  | "/br"
  | "/kr"
  | "/mx"
  | "/tr"
  | "/dk"
  | "/pt"
  | "/pr"
  | "/ar"
  | "/bg"
  | "/ca"
  | "/cl"
  | "/cn"
  | "/co"
  | "/cz"
  | "/ee"
  | "/fi"
  | "/gr"
  | "/hk"
  | "/hr"
  | "/hu"
  | "/id"
  | "/ie"
  | "/il"
  | "/in"
  | "/lt"
  | "/lv"
  | "/my"
  | "/no"
  | "/ph"
  | "/pl"
  | "/re"
  | "/ro"
  | "/ru"
  | "/se"
  | "/sg"
  | "/sk"
  | "/sl"
  | "/th"
  | "/tw"
  | "/ua"
  | "/za"
  | "/pt";

interface Country {
  code: string;
  name: string;
  icon: string;
}

const HeaderDom: React.FunctionComponent<any> = () => {
  const { t } = useTranslation("login");
  const [userName, setUserName] = useState("Welcome Admin");
  const basename = sessionStorage.getItem("basename") as baseNameType;
  const selectedCountry = countryCode[basename]?.value;
  const selectedCountryIcon = `flag-${countryCode[basename]?.value}` || "diqiu";
  const storeUrl = countryCode[basename]?.store || process.env.REACT_APP_STORE;

  useEffect(() => {
    init();
  }, []);

  const handleChangeCountry = ({ key }: { key: string }) => {
    if (selectedCountry === key) return;
    window.location.pathname = `/${key.toLocaleLowerCase()}`;
  };

  const init = () => {
    if (sessionStorage.getItem("employeeInfo")) {
      let employeeInfo = JSON.parse(
        sessionStorage.getItem("employeeInfo") as string
      );
      if (employeeInfo.name) {
        setUserName(employeeInfo.name);
      }
    }
  };

  const storeCountry = () => {
    if (!Array.isArray(userCountryList)) return <Menu />;
    if (userCountryList.length === 0) return <Menu />;
    return (
      <Menu
        onClick={handleChangeCountry}
        defaultSelectedKeys={[selectedCountry as string]}
        selectedKeys={[selectedCountry as string]}
        className={styles["country-menu"]}
      >
        {userCountryList.map((item) => (
          <Menu.Item key={item?.code}>
            <VIcon type={`flag-${item?.code}`} />
            <div className={styles["country-name"]}>{item?.name}</div>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const handleSignout = ({ key }: { key: string }) => {
    if (key === "sign_out") {
      logOut();
    }
  };

  const userCountryList = JSON.parse(
    sessionStorage.getItem("user-country-list")! || "[]"
  ).sort((a: Country, b: Country) => (a.name > b.name ? 1 : -1)) as Country[];

  return (
    <Header className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles["logo-wrapper"]}>
          <img src={logo} alt="" width="80px" />
        </div>
        <div className={styles["nav-wrapper"]}>
          {process.env.REACT_APP_ENV && (
            <div className={styles["env"]}>
              {process.env.REACT_APP_ENV.toUpperCase()} Environment
            </div>
          )}
          <div className={styles["nav-container"]}>
            <Dropdown
              trigger={["click"]}
              menu={{
                defaultSelectedKeys: [selectedCountry],
                onClick: handleChangeCountry,
                items: userCountryList.map((item) => {
                  return {
                    key: item.code,
                    icon: <VIcon type={`flag-${item.code}`} />,
                    label: (
                      <div className={styles["country-name"]}>{item?.name}</div>
                    ),
                  };
                }),
              }}
              dropdownRender={(menus) => {
                return <div className={styles["flags-dropdown"]}>{menus}</div>;
              }}
              getPopupContainer={const_config.POPUP_CONTAINER_PARENT}
            >
              <div className={styles["country-dropdown"]}>
                <VIcon
                  className={styles["country-icon"]}
                  type={selectedCountryIcon}
                />
              </div>
            </Dropdown>
          </div>
          <Dropdown
            className={styles["user-dropdown"]}
            menu={{
              className: styles["user-menu"],
              onClick: handleSignout,
              items: [
                {
                  itemIcon: <LogoutOutlined />,
                  label: t("sign_out"),
                  key: "sign_out",
                },
              ],
            }}
            trigger={["click"]}
            getPopupContainer={const_config.POPUP_CONTAINER_PARENT}
          >
            <div className={styles["user-dropdown-container"]}>
              <span className={styles.username}>{userName}</span>
              <Avatar>{userName.substring(0, 1).toUpperCase()}</Avatar>
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderDom;
