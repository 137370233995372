import classNames from "classnames";
import styles from "./styles.module.scss";
import Loader from "./loader.gif";

export default function LoadingForRC() {
  return (
    <img
      className={classNames(styles.spinner, "spinner", "ant-spin-dot")}
      src={Loader}
      alt=""
    />
  );
}
